






























































































import { Style } from '@/includes/types/Post.types'
import { BoardService } from '@/includes/services/BoardService'
import { errorNotification } from '@/includes/services/NotificationService'
import { Board, BoardPermissions } from '@/includes/types/Board.types'
import { canCreatePost } from '@/includes/PermissionHelper'

import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'
import { PostType } from 'piramis-base-components/src/shared/modules/posting/types'

import { Component, Emit, Prop, VModel, Watch } from 'vue-property-decorator'
import { Location } from 'vue-router'
import Vue from 'vue'

@Component({
  data() {
    return {
      PostType,
      canCreatePost
    }
  },
  components: {
    ConfigField
  }
})
export default class PostTypePicker extends Vue {
  @VModel() isPostPickerOpen!: boolean

  @Prop({ type: String }) activeBoardId!: string | null

  @Prop({ type: Array, default: null }) activePermissions!: Array<BoardPermissions> | null

  @Prop({ type: Boolean }) draftsOnly!: boolean

  @Prop({ type: Boolean, required: false, default: false }) needConfig!: boolean

  @Emit()
  postTypeSelect(type: PostType, board: string, query?: Location['query']): { type: PostType, board: string, query?: Location['query'] } {
    return { type, board, query }
  }

  @Emit()
  modalClose(): boolean {
    this.boardConfig = null

    return true
  }

  @Emit()
  createTemplateButtonClick(): string | null {
    return this.activeBoardId
  }

  boardConfig: Board | null = null

  loading = false

  @Watch('isPostPickerOpen')
  async onActiveBoardChange(state: boolean): Promise<void> {
    if (this.needConfig && state && this.activeBoardId) {
      try {
        this.loading = true
        this.boardConfig = await BoardService.getPostBoardConfig('tg', { board_key: this.activeBoardId })
      } catch (e) {
        errorNotification(e)
      } finally {
        this.loading = false
      }
    }
  }

  get stylesExists(): boolean {
    return !!this.styles && !!Object.keys(this.styles).length
  }

  get styles(): Record<string, Style> | null {
    const board = this.$store.state.boardsState.activeBoard

    if (board && board.config.styles !== null) {
      return board.config.styles
    }

    return this.boardConfig && this.boardConfig.config.styles
  }

  get postTypes() {
    return [
      { type: PostType.Post, title: this.$t('post_type_post').toString(), icon: 'file-text' },
      { type: PostType.Paid, title: this.$t('post_type_payed').toString(), icon: 'dollar' },
      { type: PostType.Poll, title: this.$t('post_type_poll').toString(), icon: 'bars' },
      { type: PostType.Quiz, title: this.$t('post_type_quiz').toString(), icon: 'bulb' },
    ]
  }

  handlePostTypeClick(type: PostType, styleId?: string): void {
    let query: Location['query'] = {}

    if (styleId) {
      query.styleId = styleId
    }

    if (this.activePermissions && !canCreatePost(this.activePermissions)) {
      query.suggest = 'true'
    }

    if (this.activeBoardId) {
      this.postTypeSelect(type, this.activeBoardId, query)
    }
  }
}
